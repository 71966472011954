<template>
  <div>
    <!--begin::Content header-->

    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin" style="min-width: 350px">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">{{ currentCustomer.CompanyName }}</h3>
      </div>

      <div class="card card-custom card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark"
              >Abonnements</span
            >
            <span class="text-muted mt-3 font-weight-bold font-size-sm"
              >Selectionner un abonnement</span
            >
          </h3>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-8">
          <b-overlay :show="isLoading" rounded="sm">
            <div style="max-height: 400px; overflow: auto">
              <!--begin::Item-->
              <div
                class="d-flex align-items-center mb-10"
                v-for="sub in subscriptions"
                :key="sub.SubscriptionCustomerGuid"
              >
                <!--begin::Symbol-->
                <div class="symbol symbol-40 symbol-light-info mr-5">
                  <span class="symbol-label">
                    <i class="fas fa-bars text-info"></i>
                  </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Text-->
                <div class="d-flex flex-column font-weight-bold">
                  <a
                    href="javascript:;"
                    class="text-dark text-hover-primary mb-1 font-size-lg"
                    @click="selectSubscription(sub)"
                    >{{ sub.SubscriptionLabel }}</a
                  >
                  <span class="text-muted">{{ sub.CollectionLabel }}</span>
                </div>

                <!--end::Text-->
              </div>
            </div>
          </b-overlay>
        </div>
        <button
          ref="kt_login_signin_submit"
          class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          @click="goToCustomers()"
          v-if="customers.length > 1"
        >
          Changer l'entreprise
        </button>
        <!--end::Body-->
      </div>
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import CustomerService from "../../../services/customer.service";
import {
  UPDATE_SUBSCRIPTIONS,
  SELECT_SUBSCRIPTION,
} from "../../../core/services/store/customer.module";

export default {
  name: "login",
  data() {
    return {
      isLoading: false,
      subscriptions: [],
    };
  },
  mounted: function () {
    this.getSubscriptions();
  },
  methods: {
    getSubscriptions() {
      this.isLoading = true;
      CustomerService.getSubscriptions()
        .then((result) => {         
          this.$store.dispatch(UPDATE_SUBSCRIPTIONS, result.data);
          this.subscriptions = result.data;
          if (this.subscriptions.length == 1) {
            this.selectSubscription(this.subscriptions[0]);
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    selectSubscription(sub) {
      this.$store.dispatch(SELECT_SUBSCRIPTION, sub);
      this.$router.push({ name: "dashboard" });
    },
    goToCustomers() {
      this.$router.push({ name: "customers" });
    },
  },
  computed: {
    ...mapGetters(["currentCustomer", "customers"]),
  },
};
</script>
